import {ref} from 'vue';
import {defineStore} from 'pinia';
import {useServicesStore} from "@/stores/services";
import { useProperCase } from "@/composables/useProperCase.js";

export const useCompanyStore = defineStore('company', () => {
    const servicesStore = useServicesStore();

    // This should be removed and replaced with an Industry->configuration flag if appointments are expanded
    const appointmentEnabledIndustries = ['solar', 'roofing'];

    // As all LeadCampaigns for legacy industries are being migrated with the same Service, we want this to be selected
    // by default to ensure a Company can see their CompanyCampaigns when they log in
    const defaultServices = {
        roofing: 'roof-replacement',
    };

    const id = ref(null);
    const name = ref(null);
    const industries = ref([]);
    const industryConfigurations = ref({});
    const services = ref({})
    const status = ref(null);
    const activeCustomer = ref(null);
    const contractAccepted = ref(null);
    const hasValidPaymentMethod = ref(null);
    const adminLocked = ref(null);
    const watchdogEnabled = ref(null);
    const accountManager = ref(null);
    const leadPurchasing = ref(null);
    const logoUrl = ref(null);
    const description = ref(null);
    const websiteUrl = ref(null);
    const yearStartedBusiness = ref(null);
    const selectedIndustry = ref(null);
    const selectedService = ref(null);
    const missedLeadsEnabled = ref(false);
    const appointmentsActive = ref(false);
    const futureCampaignsActive = ref(false);
    const reviewsEnabled = ref(true);
    const unrestrictedZipCodes = ref(false);

    const activeContractUuid = ref(null);

    function set(payload) {
        id.value = payload.id ?? id.value;
        name.value = payload.name ?? name.value;
        industries.value = payload.industries.length ? payload.industries : industries.value;
        industryConfigurations.value = Object.keys(payload.industryConfigurations).length ? payload.industryConfigurations : industryConfigurations.value;
        services.value = Object.keys(payload.services).length ? payload.services : services.value;
        status.value = payload.status ?? status.value;
        activeCustomer.value = payload.activeCustomer ?? activeCustomer.value;
        contractAccepted.value = payload.contractAccepted ?? contractAccepted.value;
        hasValidPaymentMethod.value = payload.hasValidPaymentMethod ?? hasValidPaymentMethod.value;
        watchdogEnabled.value = payload.watchdogEnabled ?? watchdogEnabled.value;
        adminLocked.value = payload.adminLocked ?? adminLocked.value;
        accountManager.value = payload.accountManager ?? accountManager.value;
        leadPurchasing.value = payload.leadPurchasing ?? leadPurchasing.value;
        logoUrl.value = payload.logoUrl ?? logoUrl.value;
        description.value = payload.description ?? description.value;
        websiteUrl.value = payload.websiteUrl ?? websiteUrl.value;
        yearStartedBusiness.value = payload.yearStartedBusiness ?? yearStartedBusiness.value;
        selectedIndustry.value = payload.selectedIndustry ?? selectedIndustry.value ?? industries.value[0] ?? null;
        selectedService.value = payload.selectedService ?? selectedService.value ?? (autoSelectIndustryService() && selectedService.value) ?? null;
        appointmentsActive.value = (appointmentEnabledIndustries.includes(selectedIndustry.value ?? '') && payload.appointmentsActive) ?? appointmentsActive.value ?? false;
        missedLeadsEnabled.value = payload.missedLeadsEnabled ?? missedLeadsEnabled.value ?? false;
        futureCampaignsActive.value = industryConfigurations.value[selectedIndustry.value]?.future_campaigns_active ?? false;
        reviewsEnabled.value = payload.reviewsEnabled ?? reviewsEnabled.value ?? false;
        unrestrictedZipCodes.value = payload.unrestrictedZipCodes ?? unrestrictedZipCodes.value ?? false;

        servicesStore.apiService.setCompanyId(id.value);
        servicesStore.apiService.setIndustry(selectedIndustry.value);
        servicesStore.apiService.setService(selectedService.value);
        servicesStore.apiServiceV4.setCompanyId(id.value);
        servicesStore.apiServiceV4.setIndustryKey(selectedIndustry.value);
        servicesStore.apiServiceV4.setServiceKey(selectedService.value);
    }

    function updateContractAccepted(accepted) {
        contractAccepted.value = accepted;
    }

    function updatePaymentStatus(newStatus) {
        hasValidPaymentMethod.value = newStatus;
    }

    function updateAccountStatus(newStatus) {
        status.value = newStatus;
    }

    async function updateCompanyIndustryServices(serviceIdArray) {
        const resp = await servicesStore.apiService.updateCompanyIndustryServices(serviceIdArray).catch(e=>e);
        if (resp.data?.data?.status) {
            this.$patch(resp.data.data.updated_services);
            return { status: true }
        }
        else {
            return { status: false, message: resp?.data?.data?.message || resp.message | resp.err || 'An unknown error occurred.' }
        }
    }

    async function getCompanyContractForUser() {
        const resp = await servicesStore.apiService.getCompanyContractForUser().catch(e=>e);
        if (resp.data?.data?.status) {
            return { status: true, contract: resp.data.data.contract }
        }
        else {
            return genericErrorMessage(resp);
        }
    }

    async function getNewContractForCompany() {
        const resp = await servicesStore.apiService.getNewCompanyContract().catch(e=>e);
        if (resp.data?.data?.status) {
            activeContractUuid.value = resp.data.data.reference;
            return { status: true, contract: resp.data.data.contract }
        }
        else {
            return genericErrorMessage(resp);
        }
    }

    async function agreeToCompanyContract() {
        const resp = await servicesStore.apiService.agreeToCompanyContract(activeContractUuid.value).catch(e=>e);
        if (resp.data?.data?.status) {
            updateContractAccepted(true);
            activeContractUuid.value = null;
            return { status: true }
        }
        else {
            return genericErrorMessage(resp);
        }
    }

    async function updateCompanyProfile(payload) {
        const resp = await servicesStore.apiService.updateCompanyProfile(payload).catch(e=>e);
        if (resp.data?.data?.status) {
            set({
                websiteUrl: payload.website ?? null,
                description: payload.description ?? null,
                yearStartedBusiness: payload.yearStartedBusiness
            });
            return { status: true }
        }
        else {
            return { status: false, message: resp.data.response?.message ?? resp.err ?? 'An unknown error occurred' }
        }
    }

    function autoSelectIndustryService() {
        if (!selectedService.value || !industries.value.includes(selectedIndustry.value)) {
            if (!selectedIndustry.value || !industries.value.includes(selectedIndustry.value)) {
                selectedIndustry.value = Object.keys(services.value)?.[0] ?? null;
                if (!selectedIndustry.value) return false;
            }

            selectedService.value = getDefaultServiceForIndustry() ?? services.value[selectedIndustry.value]?.[0].slug ?? null;
        }

        return !!selectedService.value;
    }

    function getSelectedIndustryName() {
        return useProperCase(selectedIndustry.value ?? '');
    }

    function getSelectedServiceName() {
        return services.value[selectedIndustry.value?.toLowerCase()]?.find(service => service.slug === selectedService.value)?.name ?? useProperCase(selectedService.value);
    }

    function genericErrorMessage(resp) {
        return { status: false, message: resp.data?.data?.message || resp.response?.data?.message || resp.err || `An unknown error occurred fetching data.` };
    }

    // This will auto-select the default service for a new login for a company that has been migrate from a legacy industry
    function getDefaultServiceForIndustry() {
        const defaultService = defaultServices[selectedIndustry.value] ?? null;
        if (defaultService) {
            const validServices = Object.values(services.value ?? {}).reduce((output, industry) => {
                return [ ...output, ...industry.map(service => service.slug) ];
            }, []);

            if (validServices.includes(defaultService))
                return defaultService;
        }

        return null;
    }

    function $reset() {
        id.value = null;
        name.value = null;
        industries.value = [];
        status.value = null;
        activeCustomer.value = null;
        contractAccepted.value = null;
        hasValidPaymentMethod.value = null;
        adminLocked.value = null;
        watchdogEnabled.value = null;
        accountManager.value = null;
        leadPurchasing.value = null;
        logoUrl.value = null;
        description.value = null;
        websiteUrl.value = null;
        selectedIndustry.value = null;
        selectedService.value = null;
        missedLeadsEnabled.value = null;
        appointmentsActive.value = null;
        reviewsEnabled.value = null;
        futureCampaignsActive.value = false;
        industryConfigurations.value = {};
    }

    return {
        id,
        name,
        industries,
        services,
        status,
        activeCustomer,
        contractAccepted,
        hasValidPaymentMethod,
        adminLocked,
        watchdogEnabled,
        accountManager,
        leadPurchasing,
        logoUrl,
        description,
        websiteUrl,
        selectedIndustry,
        selectedService,
        appointmentsActive,
        missedLeadsEnabled,
        reviewsEnabled,
        futureCampaignsActive,
        industryConfigurations,
        unrestrictedZipCodes,

        set,
        updateContractAccepted,
        updatePaymentStatus,
        updateAccountStatus,
        updateCompanyIndustryServices,
        getCompanyContractForUser,
        getNewContractForCompany,
        agreeToCompanyContract,
        updateCompanyProfile,
        autoSelectIndustryService,
        getSelectedIndustryName,
        getSelectedServiceName,
        $reset
    };
});