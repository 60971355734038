<template>
    <main
        class="h-screen w-full relative bg-image-gradient"
    >
        <GlobalAlerts class="z-[1000]" />
        <div class="relative flex h-full justify-center items-center p-3 w-128 mx-auto">
            <div class="pb-8">
                <div v-if="redirectedSolarUser && !showForgotPassword"
                     class="bg-white p-20 py-10 rounded-xl flex flex-col gap-6 mb-6 text-center"
                >
                    <p class="text-lg">
                        Welcome to the new SolarReviews Dashboard!
                    </p>
                    <p>
                        For any questions or concerns, please contact support@solarreviews.com
                    </p>
                    <p class="text-red-700">
                        Important: If you haven't set up a password on the new Dashboard yet, you'll need to do so now.
                        <span @click="() => handleForgotPassword('')"
                            class="block text-cyan-500 cursor-pointer underline"
                        >
                            Reset your password
                        </span>
                    </p>
                </div>
                <Login
                    v-show="!showForgotPassword"
                    @forgot-password="handleForgotPassword"
                    @login="handleLogin"
                    :errors="loginErrors"
                    :locked="locked"
                />
                <ForgotPassword
                    v-show="showForgotPassword"
                    :initial-email="forgotPasswordEmail"
                    :loading="passwordResetLoading"
                    :migrating-solar-user="migratingSolarUser || redirectedSolarUser"
                    :attempted-legacy-login="attemptedLegacyLogin"
                    @cancel="toggleForgotPassword(false)"
                    @reset-password="handleResetPassword"
                    :errors="resetPasswordErrors"
                    ref="forgotPasswordRef"
                />
            </div>
        </div>
    </main>
</template>

<script setup>
import {ref} from "vue";
import Login from "@/components/authentication/Login.vue";
import ForgotPassword from "@/components/authentication/ForgotPassword.vue";
import {useServicesStore} from "@/stores/services";
import {useAuthStore} from "@/stores/auth";
import {useUserStore} from "@/stores/user";
import {useCompanyStore} from "@/stores/company";
import {useErrorStore} from "@/stores/errors";
import {useRouter} from "vue-router";
import * as Sentry from '@/sentry'
import { useAlertStore } from "@/stores/v4/alerts";
import GlobalAlerts from "@/components/v4/GlobalAlerts.vue";
import FixrRoofingCalculatorLogo from "@/components/shared/FixrRoofingCalculatorLogo.vue";
import {usePasswordReset} from "@/stores/passwordReset";

const services = useServicesStore();
const auth = useAuthStore();
const user = useUserStore();
const company = useCompanyStore();
const errors = useErrorStore();
const alertStore = useAlertStore();
const passwordReset = usePasswordReset();

const router = useRouter();

const showForgotPassword = ref(false);
const forgotPasswordEmail = ref(null);
const loginErrors = ref([]);
const locked = ref(false);
const passwordResetLoading = ref(false);
const resetPasswordErrors = ref([])

const forgotPasswordRef = ref(null);

const redirectedSolarUser = ref(false);
const migratingSolarUser = ref(false);
const attemptedLegacyLogin = ref(false);

const queryParams = router.currentRoute.value.query;
if (queryParams.from) {
    if (queryParams.from === 'solar-dashboard')
        redirectedSolarUser.value = true;
}

const handleLogin = async (payload) => {
    if(locked.value)
        return;

    loginErrors.value = [];

    const {email, password} = payload

    if (!email || !password) {
        loginErrors.value.push('Email and password are required.')
        return
    }

    loginErrors.value = [];
    locked.value = true;
    let status = false;

    await services.apiService.login(email, password).then(resp => {
        if (!resp.data.data.token && resp.data.data.migrating_user) {
            handleMigratingUserResponse(resp.data.data);
        }

        const token = resp.data.data.token;
        const expiry = resp.data.data.expiry;
        auth.set({token, expiry});
        status = true;
    }).catch(e => {
        loginErrors.value = ["The email and password combination is incorrect."];
        errors.report(e);
    });

    if(!status) {
        locked.value = false;
        return;
    }

    await services.apiService.profile().then(resp => {
        const userEmail = resp.data.data.user.email
        const userId = resp.data.data.user.id

        user.set({
            id: userId,
            email: userEmail,
            firstName: resp.data.data.user.first_name,
            lastName: resp.data.data.user.last_name,
        });

        if (resp.data.data.user.must_reset_password_token) {
            passwordReset.set({
                token: resp.data.data.user.must_reset_password_token,
                email: userEmail
            });
        }

        company.set({
            id: resp.data.data.company.id,
            name: resp.data.data.company.name,
            industries: resp.data.data.company.industries,
            industryConfigurations: resp.data.data.company.industry_configurations,
            services: resp.data.data.company.services,
            status: resp.data.data.company.status,
            activeCustomer: resp.data.data.company.active_customer,
            contractAccepted: resp.data.data.company.contract_accepted,
            hasValidPaymentMethod: resp.data.data.company.has_valid_payment_method,
            watchdogEnabled: resp.data.data.company.enable_watchdog_compliance_links,
            adminLocked: resp.data.data.company.admin_locked,
            futureCampaignsActive: resp.data.data.company.future_campaigns_active ?? false,
        });

        Sentry.setUserToContext({email: userEmail, company_user_id: userId})

        router.push({name: 'dashboard'});

    }).catch(e => errors.report(e));

    locked.value = false;
}

const handleMigratingUserResponse = ({ migrating_status }) => {
    attemptedLegacyLogin.value = true;
    if (migrating_status) {
        if (redirectedSolarUser.value)
            migratingSolarUser.value = true;

        handleForgotPassword();
    }
    else {
        errors.value = [
            "We cannot log you in or reset your password at this time. If you are a SolarReviews customer, please send an email to support@solarreviews.com for assistance logging in."
        ]
    }
}

const handleResetPassword = async (email) => {
    if (passwordResetLoading.value) return;
    passwordResetLoading.value = true;

    resetPasswordErrors.value = []

    try {
        const { status, message } = await auth.requestPasswordReset(email);
        if (!status) {
            const errorMessage = message ?? "An error occurred requesting a password reset."
            resetPasswordErrors.value = [errorMessage]
        }
        else {
            forgotPasswordRef.value.confirmationSent();
        }
    } catch (err) {
        console.error(err)
    }

    passwordResetLoading.value = false;
}

const handleForgotPassword = (email) => {
    forgotPasswordEmail.value = email;
    toggleForgotPassword(true);
}

const toggleForgotPassword = (show) => {
    showForgotPassword.value = show == null
        ? !showForgotPassword.value
        : !!show;
}


</script>

<style scoped>
.bg-image-gradient {
    background: linear-gradient(360deg, #001B35 12.49%, rgba(0, 35, 68, 0.85) 63.1%, rgba(0, 79, 155, 0.8) 137.42%), url("/login_background.png");
    background-size: cover;
    background-position: center;
}

</style>