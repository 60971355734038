<template>
    <main class="h-screen w-full relative bg-cyan-500">
        <div class="relative flex h-full justify-center items-center p-3">
            <div class="pb-8">

            </div>
        </div>
    </main>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import {useServicesStore} from "@/stores/services";
import { useAuthStore } from "@/stores/auth";
import {useUserStore} from "@/stores/user";
import {useCompanyStore} from "@/stores/company";
import {logout} from "@/services/AuthService";

const router = useRouter();
const route = useRoute();
const services = useServicesStore();
const auth = useAuthStore();
const user = useUserStore();
const company = useCompanyStore();

const params = route.query;

logout();

if(!params["token"]) {
    router.push({name: 'login'})
} else {
    const token = params["token"];

    services.apiService.loginWithToken(token).then(resp => {
        if(!resp.data.data.token)
            throw new Error("Invalid token.");

        auth.set(resp.data.data);

        services.apiService.profile().then(resp => {
            user.set({
                id: resp.data.data.user.id,
                email: resp.data.data.user.email,
                firstName: resp.data.data.user.first_name,
                lastName: resp.data.data.user.last_name
            });

            let selectedService = resp.data.data.company.services?.roofing?.find(e => e.slug === "roof-replacement");

            if (!selectedService) {
                for (const [industry, services] of Object.entries(resp.data.data.company.services)) {
                    if (industry !== 'solar') {
                        selectedService = services[0]
                        break
                    }
                }
            }

            const selectedIndustrySlug = selectedService?.industry_slug;
            const selectedServiceSlug = selectedService?.slug;

            company.set({
                id: resp.data.data.company.id,
                name: resp.data.data.company.name,
                industries: resp.data.data.company.industries,
                industryConfigurations: resp.data.data.company.industry_configurations,
                services: resp.data.data.company.services,
                status: resp.data.data.company.status,
                activeCustomer: resp.data.data.company.active_customer,
                contractAccepted: auth.isShadower || resp.data.data.company.contract_accepted,
                hasValidPaymentMethod: resp.data.data.company.has_valid_payment_method,
                watchdogEnabled: resp.data.data.company.enable_watchdog_compliance_links,
                adminLocked: resp.data.data.company.admin_locked,
                selectedService: selectedServiceSlug,
                selectedIndustry: selectedIndustrySlug,
                futureCampaignsActive: resp.data.data.company.future_campaigns_active ?? false,
            });

            router.push({name: 'dashboard'});
        }).catch(_e => {
            router.push({name: 'login'})
        });
    }).catch(_e => {
        router.push({name: 'login'})
    });
}
</script>