import {BaseApiService} from "@/services/api/base";
import { faker } from '@faker-js/faker';

export class DummyApiServiceV3 extends BaseApiService {
    constructor(delay = 250) {
        super();

        this.delay = delay;
    }

    _debounce(data) {
        return new Promise((resolve) => {
            setTimeout(() => resolve({data: {data}}), this.delay);
        });
    }

    login(email, password) {
        return this._debounce({token: "abc-123-def-456", expiry: ((new Date()).getTime() / 1000) + 3600});
    }

    loginWithToken(token) {
        return this._debounce({token: "abc-123-def-456", expiry: ((new Date()).getTime() / 1000) + 3600});
    }

    updatePassword(payload) {
        return this._debounce({ status: true });
    }

    requestPasswordReset(payload) {
        return this._debounce({ status: true });
    }

    profile() {
        return this._debounce({
            user: {
                id: 12345,
                first_name: "John",
                last_name: "Doe",
                email: "john.doe@fixr.com"
            },
            company: {
                id: 12345,
                name: "John Doe Contracting",
                industries: [
                    "solar",
                    "roofing",
                    "hvac"
                ],
                status: "active",
                has_valid_payment_method: true,
                active_customer: true,
                admin_locked: false,
                enable_watchdog_compliance_links: true,
                contract_accepted: true
            }
        })
    }

    getCompany() {
        return this._debounce({
            account_manager: {
                name: "Johnny Tester",
                email: "johnny.tester@test.com",
                phone: "555-555-5555",
                meeting_url: "https://meeting.url"
            },
            contract_accepted: true,
            id: 12345,
            lead_purchasing: {
                status: "paused"
            },
            logo_url: null,
            description: "John Doe Contracting, In Business For 10 Years!",
            name: "John Doe Contracting",
            website_url: "https://solarreviews.com",
            industries: [
                "solar",
                "roofing",
                "hvac"
            ],
            services: {
                "solar": [{"id": 1, "name": "Installation", "slug": "solar-installation", "industry": "solar"}],
                "roofing": [{"id": 7, "name": "Roof Replacement", "slug": "roof-replacement", "industry": "roofing"}]
            },
            status: "active",
            has_valid_payment_method: true,
            active_customer: true,
            admin_locked: false,
            missed_leads_enabled: true
        });
    }

    getCompanyLogo() {
        return this._debounce({
            status: true,
            logo: "https://dev.fixr.com/assets/fixr-logo.svg"
        })
    }

    updateCompanyLogo(logo) {
        return this._debounce({
            status: true,
            logo: "https://dev.fixr.com/assets/fixr-logo.svg"
        });
    }

    getProfitabilityAssumption() {
        return this._debounce({
            profitability_configuration: {
                percentage_leads_successful: 75,
                average_lead_revenue: 500,
                average_job_cost: 125,
                labour_materials_cost: 200,
            },
            status: true
        });
    }

    getGlobalProfitabilityAssumptions() {
        return this._debounce({
            profitability_configuration: {
                percentage_leads_successful: 75,
                average_lead_revenue: 500,
                average_job_cost: 125,
                labour_materials_cost: 200,
            },
            status: true
        });
    }

    updateProfitabilityAssumption(payload) {
        return this._debounce({
            status: false,
            message: `Dummy API, model not updated.`,
        })
    }

    getCompanyLocations() {
        return this._debounce({
            status: true,
            locations: [
                {
                    id: 1,
                    name: "Head Office",
                    phone: "123-555-0123",
                    address: {
                        address_1: "1075 Grubsnort Lane",
                        address_2: "",
                        city: "Noway Jose",
                        state: "CA",
                        zip_code: "95129",
                        country: "US"
                    }
                },
                {
                    id: 2,
                    name: "Other Office",
                    phone: "123-555-5555",
                    address: {
                        address_1: "Suite Cheeses",
                        address_2: "2 Flapbonker Road",
                        city: "Doggin",
                        state: "CA",
                        zip_code: "95129",
                        country: "US"
                    }
                },
            ],
        });
    }

    getCountries() {
        return this._debounce({
            status: true,
            countries: {},
        });
    }

    getStates() {
        return this._debounce({
            status: true,
            countries: {},
        });
    }

    updateCompanyLocation() {
        return this._debounce({
            status: false,
            err: `Dummy API driver only, nothing updated.`
        });
    }

    createCompanyLocation() {
        return this._debounce({
            status: false,
            err: `Dummy API driver only, nothing created.`
        });
    }

    deleteCompanyLocation() {
        return this._debounce({
            status: false,
            err: `Dummy API driver only, nothing deleted.`
        });
    }

    makeCompanyLocationPrimary(locationId) {
        return this._debounce({
            status: false,
            err: `Dummy API driver only, nothing changed.`
        });
    }

    getLeadTypesPurchasedBreakdown(start, end, data = {}) {
        return this._debounce({
            status: true,
            exclusive: {
                enabled: true,
                percent: 10
            },
            duo: {
                enabled: true,
                percent: 20
            },
            trio: {
                enabled: true,
                percent: 25
            },
            quad: {
                enabled: true,
                percent: 20
            },
            unverified: {
                enabled: true,
                percent: 15
            },
            email: {
                enabled: true,
                percent: 10
            }
        })
    }

    getProfileProgress() {
        return this._debounce({
            status: true,
            items: [
                {title: "Logo and description have been added", completed: true},
                {title: "Contact details have been added", completed: false},
                {title: "Address has been added", completed: true},
                {title: "Services have been added", completed: true},
            ]
        })
    }

    getCampaignOverview() {
        return this._debounce({
            status: true,
            campaign_count: 5,
            paused_campaigns: 1
        });
    }

    getLeadVolume(params = {}) {
        return this._debounce({
            status: true,
            groups: [
                {
                    start_timestamp: 1676102400,
                    end_timestamp: 1676188799,
                    received: 12,
                    available: 20,
                    purchased: 10,
                    spent: 500
                },
                {
                    start_timestamp: 1676188800,
                    end_timestamp: 1676275199,
                    received: 6,
                    available: 22,
                    purchased: 6,
                    spent: 250
                },
                {
                    start_timestamp: 1676275200,
                    end_timestamp: 1676361599,
                    received: 5,
                    available: 30,
                    purchased: 4,
                    spent: 300
                }
            ]
        });
    }

    getMissedReasons(params = {}) {
        return this._debounce({
            status: true,
            reasons: {
                over_budget: {
                    impact: true,
                    title: "Budget too low",
                    message: "Your budget has restricted the amount of leads you received on 5 days in the last month.",
                    category: 'campaigns'
                },
                rejection_percentage: {
                    impact: true,
                    title: "Rejection % too high",
                    message: "Your rejection % is higher than some of your peers. This lowers your ranking in the lead allocation queue.",
                    category: 'leads'
                },
                outbid: {
                    impact: false,
                    title: "Getting outbid",
                    message: "Other contractors have added higher bids for specific lead types (exclusive, duo) in your service areas.",
                    category: 'bidding'
                },
                unverified: {
                    impact: true,
                    title: "Unverified leads off",
                    message: "Unverified leads have gone through the normal call to action, entering their details and phone number. but our QA team was unable to get them to confirm they want quotes. We sell these leads for 30-40% of the cost of a verified lead. they will have lower contact and appointment set rates but because of the low cost and less competition, can produce excellent cost of acquisition. Many of these people just have jobs that don't allow them to answer their phones when called.",
                    category: 'campaigns'
                },
                email: {
                    impact: false,
                    title: "Email leads off",
                    message: "More and more consumers are indicating a preference to deal via email only. As such we now have a limited number of pages on our websites that allow the consumer to select this option. They have a much lower response rate but because of the cost can yield a very good cost of acquisition. They are great for smaller installers who cannot call phone leads as quickly as big installers who have dedicated call centers and auto-dialers.",
                    category: 'campaigns'
                },
                pauses: {
                    impact: true,
                    title: "Campaigns paused too often",
                    message: "You are missing out on leads because your campaigns have been paused 3 times in the last 30 days.",
                    category: 'campaigns'
                },
            },
        });
    }

    getCompanyUsers() {
        return this._debounce({
            status: true,
            company_users: [{
                "id": 1131,
                "first_name": "Bob",
                "last_name": "Flop",
                "email": "bob@example.com",
                "is_contact": 0,
                "status": 1,
                "cell_phone": null,
                "office_phone": "1231231233",
                "user_type": "user"
            }, {
                "id": 1,
                "legacy_id": "39",
                "reference": "",
                "status": 1,
                "company_id": 39,
                "first_name": "Alex",
                "last_name": "FakeName",
                "title": null,
                "department": "",
                "email": "dbc12029fda01d1647876e9b12d562c4d153aee3@dev.solarreviews.com",
                "cell_phone": "5555555555",
                "office_phone": "5555555555",
                "zoom_info_id": null,
                "can_log_in": 0,
                "password": null,
                "failed_login_count": 0,
                "created_at": "2012-12-19T23:34:12.000000Z",
                "updated_at": "2023-03-15T03:45:31.000000Z",
                "deleted_at": null,
                "is_contact": 1,
                "email_verified_at": null,
                "phone_verified_at": null
            }]
        });
    }

    updateCompanyUser() {
        return this._debounce({
            status: false,
            err: `Dummy API driver only, nothing updated.`
        });
    }

    createCompanyUser() {
        return this._debounce({
            status: false,
            err: `Dummy API driver only, nothing Created.`
        });
    }

    deleteCompanyUser() {
        return this._debounce({
            status: false,
            err: `Dummy API driver only, nothing deleted.`
        });
    }

    getCompanyNotificationSettings() {
        return this._debounce({
            status: true,
            notification_settings: [
                {
                    "id": 1,
                    "legacy_id": 39,
                    "name": "Alex FakeName",
                    "cell_phone": "5555555555",
                    "email": "dbc12029fda01d1647876e9b12d562c4d153aee3@dev.solarreviews.com",
                    "alerts": {
                        "reviews": {
                            "sms": true,
                            "email": true
                        },
                        "invoices": {
                            "sms": true,
                            "email": true
                        },
                        "outbid": {
                            "sms": true,
                            "email": true
                        }
                    }
                },
                {
                    "id": 1152,
                    "legacy_id": 33645,
                    "name": "Bob Wob",
                    "cell_phone": null,
                    "email": "bob@example.test",
                    "alerts": {
                        "reviews": {
                            "sms": false,
                            "email": true
                        },
                        "invoices": {
                            "sms": true,
                            "email": false
                        },
                        "outbid": {
                            "sms": false,
                            "email": false
                        }
                    }
                }
            ]
        });
    }

    updateCompanyNotificationSettings() {
        return this._debounce({
            status: false,
            err: `Dummy API driver only, nothing updated.`
        });
    }


    getCampaigns() {
        return this._debounce({
            status: true,
            campaigns: [
                {
                    "id": "5a2be635-d21f-4586-b263-abe0d8234659",
                    "name": "DEFAULT",
                    "daily_spend": null,
                    "max_daily_spend": 555,
                    "max_daily_lead": 0,
                    "status": 0,
                    "property_types": [
                        {
                            "id": 1,
                            "name": "Residential",
                            "key_value": "RESIDENTIAL"
                        },
                        {
                            "id": 2,
                            "name": "Commercial",
                            "key_value": "COMMERCIAL"
                        },
                        {
                            "id": 3,
                            "name": "Industrial",
                            "key_value": "INDUSTRIAL"
                        }
                    ],
                    "state_locations_count": 2,
                    "zip_locations_count": 381
                },
                {
                    "id": "94100ce5-86d2-402e-97fb-fa99c9658e4f",
                    "name": "dsfasdf",
                    "daily_spend": null,
                    "max_daily_spend": 0,
                    "max_daily_lead": 0,
                    "status": 1,
                    "property_types": [
                        {
                            "id": 1,
                            "name": "Residential",
                            "key_value": "RESIDENTIAL"
                        }
                    ],
                    "state_locations_count": 1,
                    "zip_locations_count": 273
                },
                {
                    "id": "d1b64358-aeff-48c2-aa55-ebb2150b870b",
                    "name": "dsfasdf",
                    "daily_spend": null,
                    "max_daily_spend": 0,
                    "max_daily_lead": 0,
                    "status": 1,
                    "property_types": [
                        {
                            "id": 1,
                            "name": "Residential",
                            "key_value": "RESIDENTIAL"
                        },
                        {
                            "id": 2,
                            "name": "Commercial",
                            "key_value": "COMMERCIAL"
                        }
                    ],
                    "state_locations_count": 1,
                    "zip_locations_count": 273
                },
                {
                    "id": "43b7ed64-256c-4138-891f-ecb5b3373bf8",
                    "name": "test",
                    "daily_spend": null,
                    "max_daily_spend": 0,
                    "max_daily_lead": 0,
                    "status": 1,
                    "property_types": [
                        {
                            "id": 2,
                            "name": "Commercial",
                            "key_value": "COMMERCIAL"
                        },
                        {
                            "id": 1,
                            "name": "Residential",
                            "key_value": "RESIDENTIAL"
                        }
                    ],
                    "state_locations_count": 1,
                    "zip_locations_count": 273
                }
            ],
        });
    }

    bulkUpdateCampaigns() {
        return this._debounce({
            status: false,
            err: `Dummy API driver only, nothing updated.`
        });
    }

    getStateDetails() {
        return this._debounce({
            status: false,
        });
    }

    getCountyDetails() {
        return this._debounce({
            status: false,
        });
    }

    getStateZipCodes() {
        return this._debounce({
            status: false,
        });
    }

    saveCampaign() {
        return this._debounce({
            status: false,
            err: `Dummy API driver only, nothing saved.`
        });
    }

    getCampaignDetail(campaignUuid) {
        return this._debounce({
            status: true,
            campaign: {
                "id": "5a2be635-d21f-4586-b263-abe0d8234659",
                "name": "DEFAULT",
                "daily_spend": null,
                "max_daily_spend": 555,
                "max_daily_lead": 0,
                "daily_limit_type": "spend",
                "status": 0,
                "allow_non_budget_premium_leads": 1,
                "property_types": [
                    1,
                    2,
                    3
                ],
                "optional_leads_types": {
                    "unverified": {
                        "id": 5,
                        "max_daily_leads": 0,
                        "max_daily_spend": 55,
                        "status": 1
                    },
                    "email_only": {
                        "id": 6,
                        "max_daily_leads": 0,
                        "max_daily_spend": 55,
                        "status": 1
                    }
                },
                "zip_codes": [
                    {
                        "id": 5,
                        "state_key": "AK",
                        "county_key": "anchorage",
                        "city_name": "Anchorage",
                        "city_key": "anchorage",
                        "zip_code": "99501"
                    },
                    {
                        "id": 6,
                        "state_key": "AK",
                        "county_key": "anchorage",
                        "city_name": "Anchorage",
                        "city_key": "anchorage",
                        "zip_code": "99502"
                    },
                ]
            },
        });
    }

    deleteCampaign(campaignUuid) {
        return this._debounce({
            status: false,
            err: `Dummy API driver only, nothing deleted.`
        });
    }

    getPaymentMethods() {
        return this._debounce({
            status: true,
            methods: [
                {
                    id: "card_abcdefghijklmnop",
                    method: "Credit Card",
                    brand: "MasterCard",
                    last4: "4321",
                    expiry: "05/29",
                    expiry_month: "05",
                    expiry_year: "29",
                    name: "John Johnson",
                    address_1: "123 Fake St",
                    address_2: "",
                    city: "Denver",
                    state: "Colorado",
                    zip_code: "80202",
                    country: "USA",
                    status: null
                }
            ]
        })
    }

    createPaymentMethod(token, type = 'Credit Card') {
        return this._debounce({
            status: true
        })
    }

    deletePaymentMethod(id) {
        return this._debounce({
            status: true
        })
    }

    makePaymentMethodPrimary(id) {
        return this._debounce({
            status: true
        })
    }

    searchLeads(payload, productType = 'lead') {
        return this._debounce({
            status: true,
            products: [
                {
                    "id": faker.number.int(),
                    "legacy_id": faker.number.int(),
                    "property_type_id": faker.number.int(),
                    "date_sent": faker.date.recent(),
                    "status": "purchased",
                    "source": "https://test.com",
                    "name": faker.person.fullName(),
                    "address": `${faker.location.streetAddress()} ${faker.location.city()} ${faker.location.state()} ${faker.location.zipCode()}`,
                    "email": faker.internet.email(),
                    "phone": faker.phone.number(),
                    "cost": faker.number.float(),
                    "chargeable": faker.datatype.boolean,
                    "exclude_budget": faker.datatype.boolean,
                    "best_time_to_call": faker.date.soon(),
                    "invoice_id": faker.number.int(),
                    "sale_type": "duo",
                    "campaign_name": "test",
                    "data": {},
                    "appointment": {},
                    "appointment_status": "rejected",
                    "single_product_sale": false,
                    "demoted_status": null,
                }
            ],
            total: 1,
            limit: 5,
            offset: 0,
            total_spend: 1000
        });
    }

    checkRejectionQuota(leadId) {
        return this._debounce({
            status: true,
        });
    }

    rejectLead(leadId, payload) {
        return this._debounce({
            status: true,
        });
    }

    unrejectLead(leadId) {
        return this._debounce({
            status: true,
        });
    }

    getZipCodesByRadius(zipCode, radius) {
        return this._debounce({
            status: true,
        });    }

    getZipCodesByZipCodeStrings(zipCodeArray) {
        return this._debounce({
            status: true,
        });
    }

    searchInvoices(searchOptions = {}, withBillingConfig = false) {
        return this._debounce({
            status: true,
            invoices: [
                {
                    "0": {
                        "id": 25357,
                        "created_at": 1660026781000,
                        "due_at": 1660608000000,
                        "status": "paid",
                        "total": 684
                    }
                },
                {
                    "1": {
                        "id": 25356,
                        "created_at": 1660026556000,
                        "due_at": 1660608000000,
                        "status": "paid",
                        "total": 658
                    }
                }
            ]
        });
    }

    addCrmDeliveryIntegration(payload) {
        return this._debounce({
            status: true,
        });
    }

    updateCrmDeliveryIntegration(crmDeliveryId, payload) {
        return this._debounce({
            status: true,
        });
    }

    getCrmDeliveriesForCampaign(campaignUuid) {
        return this._debounce({
            status: true,
        });
    }

    getCrmDeliveryDetail(crmDeliveryId) {
        return this._debounce({
            status: true,
        });
    }

    deleteCrmDelivery(campaignUuid = null, crmDeliveryId) {
        return this._debounce({
            status: true,
        });
    }

    getAllServicesByIndustry() {
        return this._debounce({
            status: true,
            "services": {
                "Solar": [
                    {
                        "name": "Installation",
                        "slug": "solar-installation",
                        "id": 1,
                        "industry_id": 1,
                        "industry": "Solar"
                    },
                    {
                        "name": "Repair",
                        "slug": "solar-repair",
                        "id": 2,
                        "industry_id": 1,
                        "industry": "Solar"
                    },
                    {
                        "name": "Aggregator",
                        "slug": "solar-aggregator",
                        "id": 3,
                        "industry_id": 1,
                        "industry": "Solar"
                    }
                ],
                    "Roofing": [
                    {
                        "name": "Gutter Replacement",
                        "slug": "gutter-replacement",
                        "id": 4,
                        "industry_id": 2,
                        "industry": "Roofing"
                    },
                    {
                        "name": "Roof Repair",
                        "slug": "roof-repair",
                        "id": 5,
                        "industry_id": 2,
                        "industry": "Roofing"
                    },
                    {
                        "name": "Roof Installation",
                        "slug": "roof-installation",
                        "id": 6,
                        "industry_id": 2,
                        "industry": "Roofing"
                    },
                    {
                        "name": "Roof Replacement",
                        "slug": "roof-replacement",
                        "id": 7,
                        "industry_id": 2,
                        "industry": "Roofing"
                    }
                ],
            }
        });
    }

    updateCompanyIndustryServices(serviceIds) {
        return this._debounce({
            status: false,
            message: 'Dummy API only'
        });
    }

    downloadInvoice(invoiceId) {
        return this._debounce({
            status: false,
            message: 'Dummy API - no invoice PDF link available'
        });
    }

    getTransactionsForInvoice(invoiceId) {
        return this._debounce({
            status: true,
            transactions: []
        });
    }

    deleteContactDelivery(campaignUuid, leadDeliveryMethodId) {
        return this._debounce({
            status: false,
            message: 'Dummy API - nothing deleted.'
        });
    }


    getCompanyContractForUser() {
        return this._debounce({
            status: true,
            contract: 'Dummy API - No contract to fetch.'
        });
    }

    getNewCompanyContract() {
        return this._debounce({
            status: true,
            contract: 'Dummy Contract Content',
            reference: 'dummy-uuid'
        });
    }

    agreeToCompanyContract(contractUuid) {
        return this._debounce({
            status: false,
            message: `Dummy API only.`
        });
    }

    getZipCodesForCampaignList(campaignUuidArray) {
        return this._debounce({
            status: true,
            zip_codes: [ '55555', '12345' ]
        });
    }

    updateCompanyProfile(payload) {
        return this._debounce({
            status: false,
            message: 'Dummy API - nothing updated.'
        });
    }

    getCompanyMedia() {
        return this._debounce({
            status: true,
            media_files: [
                {"id":1,"url":"https://www.youtube.com/watch?v=CANocXrAyk8","type":"youtube"}
            ]
        });
    }

    uploadCompanyMedia(payload) {
        return this._debounce({
            status: false,
            message: 'Dummy API - nothing uploaded.'
        });
    }

    addYoutubeLink(urlString) {
        return this._debounce({
            status: false,
            message: 'Dummy API - nothing uploaded.'
        });
    }

    deleteCompanyMediaAsset(assetId) {
        return this._debounce({
            status: false,
            message: 'Dummy API - nothing deleted.'
        });
    }

    getCompanyLicenses() {
        return this._debounce({
            status: true,
            licenses: [
                {"id":1,"name":"Blobby","industry_id":2,"industry":"Roofing","issued_at":null,"expires_at":1681622460000,"url":"wwww.stuff.com","state":"AR","country":"US","license_number":"123123123","type":"Whatsit"}
            ]
        });
    }

    createCompanyLicense(payload) {
        return this._debounce({
            status: false,
            message: 'Dummy API - nothing created.'
        });
    }

    updateCompanyLicense(licenseId, payload) {
        return this._debounce({
            status: false,
            message: 'Dummy API - nothing updated.'
        });
    }

    deleteCompanyLicense(licenseId) {
        return this._debounce({
            status: false,
            message: 'Dummy API - nothing deleted.'
        });
    }

    searchAppointments(payload, productType = 'appointments') {
        return this._debounce({
            status: true,
            appointments: [
                {
                    id: 6,
                    appointment_type: "in-home-appointment",
                    appointment: 1680288130000,
                    appointment_date: "2023-03-31",
                    appointment_time: "18:42:10",
                    date_sent: 1663463106000,
                    name: "Mavis Steuber",
                    address: "37622 Emmanuelle Branch, Port Imelda WV, 47968",
                    email: "moshe40@example.com",
                    phone: "586-870-7135",
                    cost: 29.95,
                    exclude_budget: 0,
                    rejected: false,
                    invoice_id: "",
                    sale_type: "Quad",
                    campaign_name: ""
                },
                {
                    id: 165,
                    appointment_type: "online-consultation",
                    appointment: 1680456109000,
                    appointment_date: "2023-04-02",
                    appointment_time: "17:21:49",
                    date_sent: 1663463106000,
                    name: "Mavis Steuber",
                    address: "37622 Emmanuelle Branch, Port Imelda WV, 47968",
                    email: "moshe40@example.com",
                    phone: "586-870-7135",
                    cost: 29.95,
                    exclude_budget: 0,
                    rejected: false,
                    invoice_id: "",
                    sale_type: "Quad",
                    campaign_name: ""
                }
            ],
        });
    }

    getPriceRangeForCampaign(campaignUuid, productName) {
        return this._debounce({
            status: true,
            "prices": {
                "EXCLUSIVE": {
                    "min": 100,
                    "max": 222
                },
                "DUO": {
                    "min": 100,
                    "max": 150
                },
                "TRIO": {
                    "min": 100,
                    "max": 150
                },
                "QUAD": {
                    "min": 100,
                    "max": 150
                },
                "UNVERIFIED": {
                    "min": 100,
                    "max": 150
                },
                "EMAIL_ONLY": {
                    "min": 100,
                    "max": 150
                }
            }
        });
    }

    getPriceRangeForZipCodes(zipCodeIds, productName) {
        return this.getPriceRangeForCampaign();
    }

    getProductStatisticsByLocation(stateAbbreviation, countyKey, productCategory) {
        return this._debounce({
            status: true,
            "statistics": {
                "Standard": {
                    "UNVERIFIED": {
                        "available": 10,
                        "received": 2,
                        "purchased": 2,
                        "cost": 200
                    },
                    "EMAIL_ONLY": {
                        "available": 0,
                        "received": 0,
                        "purchased": 0,
                        "cost": 0
                    },
                    "EXCLUSIVE": {
                        "available": 0,
                        "received": 0,
                        "purchased": 0,
                        "cost": 0
                    },
                    "DUO": {
                        "available": 3,
                        "received": 0,
                        "purchased": 0,
                        "cost": 0
                    },
                    "TRIO": {
                        "available": 0,
                        "received": 0,
                        "purchased": 0,
                        "cost": 0
                    },
                    "QUAD": {
                        "available": 7,
                        "received": 2,
                        "purchased": 1,
                        "cost": 100
                    }
                },
                "Premium": {
                    "UNVERIFIED": {
                        "available": 0,
                        "received": 0,
                        "purchased": 0,
                        "cost": 0
                    },
                    "EMAIL_ONLY": {
                        "available": 0,
                        "received": 0,
                        "purchased": 0,
                        "cost": 0
                    },
                    "EXCLUSIVE": {
                        "available": 1,
                        "received": 0,
                        "purchased": 0,
                        "cost": 0
                    },
                    "DUO": {
                        "available": 0,
                        "received": 0,
                        "purchased": 0,
                        "cost": 0
                    },
                    "TRIO": {
                        "available": 0,
                        "received": 0,
                        "purchased": 0,
                        "cost": 0
                    },
                    "QUAD": {
                        "available": 0,
                        "received": 0,
                        "purchased": 0,
                        "cost": 0
                    }
                },
            }
        });
    }

    getPricesForLocation(campaignUuid, params) {
        return this._debounce({
            status: true,
            "prices": {
                "Standard": [
                    {
                        "id": 3745,
                        "state_location_id": 1,
                        "service_product_id": 8,
                        "sale_type_id": 1,
                        "quality_tier_id": 1,
                        "property_type_id": 1,
                        "floor_price": 100,
                        "bid_price": 105
                    },
                    {
                        "id": 3746,
                        "state_location_id": 1,
                        "service_product_id": 8,
                        "sale_type_id": 2,
                        "quality_tier_id": 1,
                        "property_type_id": 1,
                        "floor_price": 100,
                        "bid_price": 110
                    },
                    {
                        "id": 3747,
                        "state_location_id": 1,
                        "service_product_id": 8,
                        "sale_type_id": 3,
                        "quality_tier_id": 1,
                        "property_type_id": 1,
                        "floor_price": 100,
                        "bid_price": null
                    },
                    {
                        "id": 3748,
                        "state_location_id": 1,
                        "service_product_id": 8,
                        "sale_type_id": 4,
                        "quality_tier_id": 1,
                        "property_type_id": 1,
                        "floor_price": 100,
                        "bid_price": 135
                    },
                    {
                        "id": 3749,
                        "state_location_id": 1,
                        "service_product_id": 8,
                        "sale_type_id": 5,
                        "quality_tier_id": 1,
                        "property_type_id": 1,
                        "floor_price": 100,
                        "bid_price": null
                    },
                    {
                        "id": 3750,
                        "state_location_id": 1,
                        "service_product_id": 8,
                        "sale_type_id": 6,
                        "quality_tier_id": 1,
                        "property_type_id": 1,
                        "floor_price": 100,
                        "bid_price": 110
                    }
                ],
                "Premium": [
                    {
                        "id": 3757,
                        "state_location_id": 1,
                        "service_product_id": 8,
                        "sale_type_id": 1,
                        "quality_tier_id": 2,
                        "property_type_id": 1,
                        "floor_price": 150,
                        "bid_price": 160
                    },
                    {
                        "id": 3758,
                        "state_location_id": 1,
                        "service_product_id": 8,
                        "sale_type_id": 2,
                        "quality_tier_id": 2,
                        "property_type_id": 1,
                        "floor_price": 150,
                        "bid_price": 155
                    },
                    {
                        "id": 3759,
                        "state_location_id": 1,
                        "service_product_id": 8,
                        "sale_type_id": 3,
                        "quality_tier_id": 2,
                        "property_type_id": 1,
                        "floor_price": 150,
                        "bid_price": 155
                    },
                    {
                        "id": 3760,
                        "state_location_id": 1,
                        "service_product_id": 8,
                        "sale_type_id": 4,
                        "quality_tier_id": 2,
                        "property_type_id": 1,
                        "floor_price": 150,
                        "bid_price": null
                    },
                    {
                        "id": 3761,
                        "state_location_id": 1,
                        "service_product_id": 8,
                        "sale_type_id": 5,
                        "quality_tier_id": 2,
                        "property_type_id": 1,
                        "floor_price": 150,
                        "bid_price": null
                    },
                    {
                        "id": 3762,
                        "state_location_id": 1,
                        "service_product_id": 8,
                        "sale_type_id": 6,
                        "quality_tier_id": 2,
                        "property_type_id": 1,
                        "floor_price": 150,
                        "bid_price": null
                    }
                ]
            }
        });
    }

    updateBidPriceForLocation(campaignUuid, payload) {
        return this._debounce({
            status: true,
        });
    }

    getAllCompanySchedules() {
        return this._debounce({
            status: true,
            schedules: [
                {
                    "id": 4,
                    "name": "test1",
                    "timezone": "Eastern",
                    "timezone_offset": -5,
                    "items": {
                        "sundays": [
                            {
                                "start": "8:00:00",
                                "end": "21:00:00"
                            }
                        ],
                        "mondays": [],
                        "tuesdays": [],
                        "wednesdays": [],
                        "thursdays": [],
                        "fridays": [],
                        "saturdays": []
                    },
                    "override_items": [
                        {
                            "date": "2023-08-30",
                            "start": "8:00:00",
                            "end": "22:00:00"
                        }
                    ],
                    "platform": "internal",
                    "is_owner": false,
                    "owner": "Hans G",
                    "online_duration": 15,
                    "in_home_duration": 15,
                    "online_buffer_before": 0,
                    "online_buffer_after": 0,
                    "in_home_buffer_before": 0,
                    "in_home_buffer_after": 0,
                    "campaigns": []
                },
                {
                    "id": 8,
                    "name": "blip",
                    "timezone": "Alaska",
                    "timezone_offset": -9,
                    "items": {
                        "sundays": [
                            {
                                "start": "14:53:00",
                                "end": "14:59:00"
                            }
                        ],
                        "mondays": [
                            {
                                "start": "20:00:00",
                                "end": "21:00:00"
                            }
                        ],
                        "tuesdays": [],
                        "wednesdays": [],
                        "thursdays": [],
                        "fridays": [],
                        "saturdays": []
                    },
                    "override_items": [
                        {
                            "date": "2023-08-23",
                            "start": "9:00:00",
                            "end": "20:00:00"
                        }
                    ],
                    "platform": "internal",
                    "is_owner": true,
                    "owner": "User Ofthings",
                    "online_duration": 15,
                    "in_home_duration": 15,
                    "online_buffer_before": 30,
                    "online_buffer_after": 10,
                    "in_home_buffer_before": 30,
                    "in_home_buffer_after": 30,
                    "campaigns": []
                }
            ],
        });
    }

    createInternalCalendar(schedulePayload) {
        return this._debounce({
            status: false,
            message: 'Dummy API only, cannot create Calendar.'
        });
    }

    updateSchedule(schedulePayload) {
        return this._debounce({
            status: false,
            message: 'Dummy API only, cannot update Schedule.'
        });    }

    deleteSchedule(scheduleId) {
        return this._debounce({
            status: false,
            message: 'Dummy API only, cannot delete Schedule.'
        });
    }

    getUnsoldProducts(searchOptions = {}, includeSoldCount = false, productIds = []) {
        return this._debounce({
            status              : true,
            products_paginated  : []
        });
    }

    purchaseProducts(_payload) {
        return this._debounce({
            status  : false,
            message : 'Dummy API - nothing saved.'
        });
    }

    reserveUnsoldProducts(_payload) {
        return this._debounce({
            status  : false,
            message : 'Dummy API - nothing updated.'
        });
    }

    getWatchdogPlaybackUrl(leadId) {
        return this._debounce({
            url: faker.internet.url()
        });
    }
}